import React, { useEffect, useState } from "react";
import {
  Text,
  Heading,
  Image,
  Pane,
  TextInput,
  Button,
  Paragraph,
  toaster,
  Link,
} from "evergreen-ui";
import logo from "../../assets/logo-with-text.svg";
import jainDemo from "../../assets/jain-demo.png";
import moneyMouthEmoji from "../../assets/money-mouth.png";
import lockEmoji from "../../assets/lock.png";
import juggleEmoji from "../../assets/juggle.png";
import andyHeadshot from "../../assets/andy-headshot.png";
import huyHeadshot from "../../assets/huy-headshot.png";
import "./styles.css";
import "../../index.css";
import { Link as ReactRouterLink } from "react-router-dom";
import { primaryColor, secondaryTextColor } from "../../styles";

export function Landing() {
  const [navbarBackgroundColor, setNavbarBackgroundColor] =
    useState("rgba(0,0,0,0.0)");

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const backgroundcolor = window.scrollY < 70 ? "rgba(0,0,0,0.0)" : "white";

      setNavbarBackgroundColor(backgroundcolor);
    });
  }, []);

  const steps = [
    {
      icon: lockEmoji,
      label: "Private & Secure",
      description:
        "Your data stays in your Snowflake account, it is not indexed anywhere else.",
    },
    {
      icon: moneyMouthEmoji,
      label: "Cost Effective",
      description:
        "Why pay egress, ingress, and storage fees to index data to use with LLMs? Bring AI to your Snowflake data instead.",
    },
    {
      icon: juggleEmoji,
      label: "Flexible",
      description:
        "Use any LLM! Support for OpenAI using your API key is available now. Bedrock, Claude, Llama coming soon.",
      subText:
        "(Fine-tuning models and privately hosting within your Snowflake account also coming soon)",
    },
  ];

  return (
    <Pane className="landing">
      <Navbar backgroundColor={navbarBackgroundColor} />

      <Pane position="relative" paddingTop={53}>
        <Pane
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
          >
            <Promo />

            <Heading
              marginTop={20}
              fontSize={40}
              fontWeight={600}
              marginBottom={16}
              lineHeight={1}
            >
              Bring AI to{" "}
              <span
                style={{
                  textDecoration: "underline #DE01FF",
                  textDecorationThickness: "7%",
                  textUnderlineOffset: "10%",
                }}
              >
                where
              </span>{" "}
              your data lives
            </Heading>
            <Text
              color={secondaryTextColor}
              fontWeight={500}
              fontSize={16}
              marginTop={16}
              marginBottom={16}
              width={700}
            >
              Jain lets you extract insights from your unstructured data with
              LLMs all within your Snowflake data warehouse. No worrying about
              data migration, security, and scaling.
            </Text>
          </Pane>

          <GetUpdatesForm />

          <Image
            className="unselectable"
            src={jainDemo}
            width="60%"
            height="auto"
            marginTop="2%"
          />

          <Pane marginTop={30}>
            <Pane
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginBottom={10}
            >
              <Text color="#6941C6" fontWeight={600}>
                Why us?
              </Text>
            </Pane>
            <Pane display="flex" justifyContent="center" alignItems="center">
              <Text
                fontSize={30}
                fontWeight={600}
                color="black"
                marginBottom={20}
              >
                Jain is built for Snowflake users
              </Text>
            </Pane>

            <Pane
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginX={200}
            >
              <Text textAlign="center" color={secondaryTextColor}>
                Harness, monitor, and assess the capabilities of external LLMs
                all within your Snowflake account through our hosted data
                solution.
              </Text>
            </Pane>

            <Pane
              clearfix
              marginTop={60}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {steps.map((step) => (
                <Step
                  key={step.label}
                  icon={step.icon}
                  label={step.label}
                  description={step.description}
                  subText={step.subText}
                />
              ))}
            </Pane>

            <Pane
              backgroundColor="#F9F5FF"
              marginTop={40}
              paddingTop={100}
              paddingBottom={100}
              borderRadius={16}
            >
              <Pane display="flex" justifyContent="center" alignItems="center">
                <Text
                  fontSize={30}
                  fontWeight={600}
                  color="#6941C6"
                  marginBottom={20}
                >
                  Meet the Team
                </Text>
              </Pane>

              <Pane
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginX={200}
              >
                <Text textAlign="center" color={secondaryTextColor}>
                  Experience with building AI at Together.ai and Stripe
                </Text>
              </Pane>

              <Pane
                clearfix
                marginTop={30}
                display="flex"
                flexDirection="row"
                justifyContent="center"
              >
                <Pane
                  maxWidth={300}
                  minWidth={250}
                  justifyContent="center"
                  textAlign="center"
                >
                  <Text
                    className="violet-gradient"
                    style={{ fontSize: 40, fontWeight: 200 }}
                  >
                    <Image
                      className="unselectable"
                      src={huyHeadshot}
                      height={180}
                    />
                  </Text>
                  <Paragraph
                    color="black"
                    fontSize={16}
                    fontWeight={500}
                    marginTop={15}
                  >
                    Huy Ngo
                  </Paragraph>
                  <Paragraph color={secondaryTextColor} marginTop={5}>
                    CEO
                  </Paragraph>
                </Pane>
                <Pane
                  maxWidth={300}
                  minWidth={250}
                  justifyContent="center"
                  textAlign="center"
                >
                  <Text
                    className="violet-gradient"
                    style={{ fontSize: 40, fontWeight: 200 }}
                  >
                    <Image
                      className="unselectable"
                      src={andyHeadshot}
                      height={180}
                    />
                  </Text>
                  <Paragraph
                    color="black"
                    fontSize={16}
                    fontWeight={500}
                    marginTop={15}
                  >
                    Andy Li
                  </Paragraph>
                  <Paragraph color={secondaryTextColor} marginTop={5}>
                    CTO
                  </Paragraph>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
          <Footer />
        </Pane>
      </Pane>
    </Pane>
  );
}

interface NavbarProps {
  backgroundColor: string;
}

function Promo() {
  const textColor = "#6941C6";
  const backgroundColor = "#F9F5FF";

  return (
    <Pane marginTop={60} className="unselectable">
      <Button
        color={textColor}
        backgroundColor={backgroundColor}
        height={15}
        style={{ borderRadius: 100 }}
        paddingY={13}
        paddingLeft={3}
        paddingRight={8}
        borderColor={backgroundColor}
        tabIndex={-1}
      >
        <Button
          color={textColor}
          borderColor="white"
          height={20}
          style={{ borderRadius: 100 }}
          paddingX={8}
          marginRight={8}
          tabIndex={-1}
        >
          Closed Beta
        </Button>
        Release slated for May 2024
      </Button>
    </Pane>
  );
}

function Navbar({ backgroundColor }: NavbarProps) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Pane
      paddingLeft={45}
      paddingTop={15}
      paddingBottom={15}
      top={0}
      width="100%"
      alignItems="center"
      backgroundColor={backgroundColor}
      position="fixed"
      zIndex={3}
      display="block"
      transition="all 1s ease"
    >
      <Pane display="flex" justifyContent="center" alignItems="center">
        <Link
          onClick={async () => {
            const event = "Jain Topnav Logo pressed";
            try {
              await fetch(`/t?event=${encodeURI(event)}`);
            } catch (e: any) {
              console.warn(e.toString());
            }
            scrollToTop();
          }}
          is={ReactRouterLink}
          to="/"
          style={{ textDecoration: "none" }}
        >
          <Image src={logo} height={26} paddingTop={4} />
        </Link>
        <Pane marginX={20} />
        <Link
          onClick={async () => {
            const event = "Join List button pressed";
            try {
              await fetch(`/t?event=${encodeURI(event)}`);
            } catch (e: any) {
              console.warn(e.toString());
            }
            scrollToTop();
          }}
          target="_blank"
        >
          <Button
            className="contact-link"
            fontWeight={500}
            appearance="minimal"
            marginRight={50}
          >
            Join List
          </Button>
        </Link>
      </Pane>
    </Pane>
  );
}

interface StepProps {
  icon: string;
  label: string;
  description: string;
  subText?: string;
}

function Step({ icon, label, description, subText }: StepProps) {
  return (
    <Pane
      maxWidth={300}
      minWidth={250}
      marginX={20}
      justifyContent="center"
      textAlign="center"
    >
      <Text
        className="violet-gradient"
        style={{ fontSize: 40, fontWeight: 200 }}
      >
        <Image className="unselectable" src={icon} height={90} />
      </Text>
      <Paragraph color="black" fontSize={16} fontWeight={500} marginTop={15}>
        {label}
      </Paragraph>
      <Paragraph color={secondaryTextColor} marginTop={5}>
        {description}
      </Paragraph>
      {subText && (
        <Paragraph color={secondaryTextColor} marginTop={5} fontSize={11}>
          {subText}
        </Paragraph>
      )}
    </Pane>
  );
}

interface GetUpdatesFormProps {
  darkBackground?: boolean;
}
function GetUpdatesForm({ darkBackground }: GetUpdatesFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <Pane
      justifyContent="center"
      width={450}
      marginBottom={16}
      is="form"
      display="flex"
      alignItems="center"
      onSubmit={async (e: any) => {
        e.preventDefault();

        setIsLoading(true);

        const event = "Email Submission button pressed";
        const trackEvent = fetch(`/t?event=${encodeURI(event)}`);
        const subscribeEmail = fetch(`/join?email=${encodeURI(email)}`);
        await Promise.all([trackEvent, subscribeEmail]);

        toaster.success("Thanks for signing up! ✨", {
          id: "get-updates-button",
        });
        setIsLoading(false);
      }}
    >
      <Pane width={200}>
        <TextInput
          id="email"
          name="email"
          type="email"
          autoComplete="username"
          width="100%"
          height={32}
          required
          placeholder="your@email.com"
          onChange={(e: any) => setEmail(e.target.value)}
          className="email-input"
        />
        <Button
          appearance="primary"
          backgroundColor={primaryColor}
          borderColor={primaryColor}
          type="submit"
          marginTop={10}
          width="100%"
          height={40}
          display="flex"
          justifyContent="center"
          isLoading={isLoading}
          borderRadius={10}
          className="join-button"
        >
          Join waiting list
        </Button>
      </Pane>
    </Pane>
  );
}

function Copyright() {
  return (
    <Text
      className="unselectable"
      fontSize={13}
      color="#98A2B3"
      fontWeight={500}
    >
      © 2024 NxtLabs. All rights reserved.
    </Text>
  );
}

function Footer() {
  return (
    <>
      <Pane
        marginTop={80}
        paddingBottom={20}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="92%"
      >
        <Image src={logo} style={{ width: 80 }} />
        <Copyright />
      </Pane>
    </>
  );
}
