export const primaryColor = '#7233FF'
export const neutralColor = 'rgba(230, 232, 240, 1)'
export const secondaryTextColor = '#667085'
export const dangerColor = 'rgba(209, 67, 67, 1)'
export const disabledPrimaryColor = 'rgba(51, 157, 255, 0.5)'
export const disabledColor = '#C1C4D6'
export const violetGradient = 'linear-gradient(90deg, rgba(255,144,244,1) 0%, rgba(95,68,255,1) 100%)'

export const primaryButton = { backgroundColor: primaryColor, borderColor: primaryColor }
export const disabledPrimaryButton = { backgroundColor: disabledPrimaryColor, borderColor: disabledPrimaryColor }
