import React, { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import { Landing } from "./pages/landing";

export default function Routes(): ReactElement {
  return (
    <Switch>
      <Route path="/" component={Landing}></Route>
    </Switch>
  );
}
