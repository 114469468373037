import React, { ReactElement } from "react";
import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { defaultTheme, ThemeProvider, Button } from "evergreen-ui";
import { primaryColor } from "./styles";

interface Theme {
  colors: Record<string, string>;
}

const theme = {
  ...defaultTheme,
  colors: { ...(defaultTheme as any).colors, blue500: primaryColor },
  defaultButton: {
    color: primaryColor,
    borderColor: primaryColor,
  },
};

export default function App(): ReactElement {
  return (
    <ThemeProvider value={theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}
